<template>
  <vx-table :columns="columns" :resource="resource" :filters="filters" />
</template>

<script>
import { dropReasonTypes, drops, dropStatuses, passDataToResource } from '@/services/resources'
import { filterTypes, sortOrders } from '@/services/table'
import { VxTable, VxCellPlayerAvatar, VxCellMoney, VxCellDate, VxCellLink, VxCellDropStatus } from '@/components/table'
import { buildRoute, path } from '@/router'

export default {
  name: 'Drops',
  components: {
    VxTable,
    VxCellPlayerAvatar,
    VxCellMoney,
    VxCellDropStatus
  },
  setup () {
    const resource = passDataToResource(drops.getAll, {
      requestParams: {
        params: { include: 'player' }
      }
    })

    const reasonTypeLinks = {
      [dropReasonTypes['chest-opening']]: path.caseOpens,
      [dropReasonTypes.upgrade]: path.upgrades,
      [dropReasonTypes.exchange]: path.exchanges,
      [dropReasonTypes['lucky-roll-result']]: path.luckyRollsWinners
    }

    const getReasonTypeRoute = (reasonType, id) => {
      const route = reasonTypeLinks[reasonType]
      return route && buildRoute(route, { query: { id } })
    }

    const columns = [
      {
        key: 'id',
        sortable: true,
        defaultSort: sortOrders.desc
      },
      {
        key: 'player',
        component: VxCellPlayerAvatar
      },
      {
        key: 'reason_type',
        label: 'Source Name',
        component: VxCellLink,
        tdAttr: (reasonType, key, { reason_id }) => ({
          label: reasonType,
          subLabel: `#${reason_id}`,
          href: getReasonTypeRoute(reasonType, reason_id),
          showLabelWithoutHref: true
        })
      },
      {
        key: 'item.id',
        label: 'Item Id'
      },
      {
        key: 'item.title',
        label: 'Item'
      },
      {
        key: 'main_price',
        label: 'Price',
        component: VxCellMoney,
        sortable: true
      },
      {
        key: 'status',
        component: VxCellDropStatus
      },
      {
        key: 'created_at',
        component: VxCellDate,
        tdAttr: { showTime: true },
        sortable: true
      },
      {
        key: 'updated_at',
        component: VxCellDate,
        tdAttr: { showTime: true },
        sortable: true
      }
    ]

    const filters = [
      {
        key: 'id',
        type: filterTypes.number
      },
      {
        key: 'player_id',
        type: filterTypes.number
      },
      {
        key: 'item_title',
        type: filterTypes.text
      },
      {
        key: 'reason_type',
        type: filterTypes.select,
        options: Object.values(dropReasonTypes)
      },
      {
        key: 'status',
        type: filterTypes.select,
        options: Object.values(dropStatuses)
      },
      {
        key: 'created_at',
        type: filterTypes.dateRangePicker,
        apiKeys: ['from', 'to']
      }
    ]

    return {
      resource,
      columns,
      filters
    }
  }
}
</script>
